/**
 * TODO: there's currently no syncronization between the highlighted menu with the pathname
 * if a user refresh a page, the menu highlight is gone
 */
import { useState } from 'react';

import { Menu, Layout, Typography } from 'antd';
import { Link } from 'react-router-dom';

import AuthenticationApi from 'api/authentication';

import './style.scss';

const { Sider } = Layout;
const { SubMenu, Item } = Menu;
const { Title } = Typography;

const menuOptions = [
  {
    type: 'Menu',
    name: 'Dashboard',
    link: '/',
  },
  {
    type: 'Menu',
    name: 'Clients',
    link: '/clients',
  },
  // temporary hide
  // {
  //   type: 'Menu',
  //   name: 'Invoices',
  //   link: '/invoices',
  // },
  {
    type: 'Menu',
    name: 'Email Request',
    link: '/email-request',
  },
  {
    type: 'Menu',
    name: 'Admins',
    link: '/admins',
  },
  {
    type: 'Menu',
    name: 'Terms and Policy',
    link: '/terms-and-policy',
  },
];

function renderMenu(opt, parent = '') {
  const { name = '', link = '', type = 'Menu', children = [] } = opt;

  if (type === 'Menu') {
    return (
      <Item key={`${parent}${name}`}>
        <Link to={link}>{name}</Link>
      </Item>
    );
  } else {
    return (
      <SubMenu key={name} title={<span>{name}</span>}>
        {children.map((opt) => renderMenu(opt, `${name}-`))}
      </SubMenu>
    );
  }
}

export default function Sidebar() {
  const [isCollapsed, setIsCollapsed] = useState(true);

  async function handleLogout() {
    const cms_token = localStorage.getItem('cms_token');

    if (cms_token) {
      AuthenticationApi.logout(cms_token);
      localStorage.removeItem('cms_token');
      localStorage.removeItem('current_admin');
    }
  }

  function handleCollapse(collapse) {
    setIsCollapsed(collapse);
  }
  return (
    <Sider
      collapsed={isCollapsed}
      collapsedWidth={0}
      collapsible
      onCollapse={handleCollapse}
      width="300"
      className={!isCollapsed && 'pd-cms-sidebar'}>
      <div className="wrapper">
        <div className="menu">
          <Title level={4} className="pd-margin-top-lg">
            GREENCUTS
          </Title>
          <Menu mode="inline">{menuOptions.map((opt) => renderMenu(opt))}</Menu>
        </div>
        <div className="bottom-menu">
          {/* temporary hide
          <Title level={4}>
            <Link to={'/setting'}>Setting</Link>
          </Title> */}
          <Title level={4} onClick={() => handleLogout()}>
            <Link to={'/login'}>Logout</Link>
          </Title>
        </div>
      </div>
    </Sider>
  );
}
