import { Col, Row, Typography } from 'antd';
import { Container, PdButton } from 'components';

import { fields } from './meta';
import { detailInvoice } from '../mock-data';
import './style.scss';

const { Text } = Typography;

function renderField(field = {}, isHalf = false) {
  if (Array.isArray(field)) {
    return field.map((col) => renderField(col, (isHalf = true)));
  }

  const { label = '', key = '' } = field;
  return (
    <>
      <Col span={6}>
        <Text>{label}</Text>
      </Col>
      <Col span={isHalf ? 6 : 18}>
        <Text>{detailInvoice[key]}</Text>
      </Col>
    </>
  );
}

export default function InvoiceDetail({ history }) {
  return (
    <Container className="pd-invoice-detail pd-padding-left-lg pd-padding-right-lg">
      {fields.map((field, idx) => {
        return <Row key={idx}>{renderField(field)}</Row>;
      })}
      <Row
        type="flex"
        justify="center"
        gutter={16}
        className="pd-margin-top-lg">
        <Col span={5}>
          <PdButton block ghost onClick={() => history.goBack()}>
            Back
          </PdButton>
        </Col>
        <Col span={5}>
          <PdButton htmlType="submit" block>
            PDF Print
          </PdButton>
        </Col>
      </Row>
    </Container>
  );
}
