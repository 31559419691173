import React from 'react';
import { Form } from 'antd';

import './style.scss';

function PdForm({ children, ...props }) {
  return <Form {...props}>{children}</Form>;
}

export default PdForm;
