import React from 'react';
import { Col, Layout, Row } from 'antd';

import DashboardContainer from 'components/container';
import PdEditIcon from 'components/pd-edit-icon';
import './style.scss';

export default function TermPolicy({ history }) {
  const termAndPolicy = [
    {
      title: 'Privacy Policy',
      link: '/terms-and-policy/policy',
    },
    {
      title: 'Terms and Conditions',
      link: '/terms-and-policy/terms',
    },
  ];

  function handleNavigate(link) {
    return () => history.push(link);
  }

  return (
    <Layout className="pd-cms-term">
      <DashboardContainer>
        {termAndPolicy.map((term, idx) => (
          <Row key={idx} align="middle">
            <Col lg={20}>
              <h1>{term.title}</h1>
            </Col>
            <Col lg={4}>
              <PdEditIcon onClick={handleNavigate(term.link)} />
            </Col>
          </Row>
        ))}
      </DashboardContainer>
    </Layout>
  );
}
