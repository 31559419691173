export const DeleteSvg = () => (
  <svg
    width="20"
    height="26"
    viewBox="0 0 28 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.1562 5.34933L27.9131 7.59962C28.0855 8.11194 27.9981 8.6795 27.6798 9.11763C27.3615 9.5555 26.8465 9.81723 26.302 9.81723H25.8638L24.1224 31.1736C23.9932 32.7585 22.6371 34 21.0353 34H7.31807C5.71605 34 4.36015 32.7585 4.23068 31.1738L2.48925 9.81723H1.69805C1.15349 9.81723 0.638491 9.5555 0.320179 9.11737C0.00186771 8.67924 -0.0854915 8.11194 0.0868729 7.59962L0.843811 5.34933C1.13204 4.49228 1.93789 3.91641 2.84889 3.91641H9.21041V1.86223C9.21041 0.835526 10.0524 0 11.0871 0H16.9129C17.9479 0 18.7896 0.835526 18.7896 1.86223V3.91641H25.1514C26.0621 3.91641 26.868 4.49228 27.1562 5.34933ZM16.7808 1.99219H11.2192V3.91641H16.7808V1.99219ZM22.1202 31.013C22.0747 31.571 21.5982 32.0078 21.0353 32.0078H7.31807C6.7552 32.0078 6.27865 31.571 6.23314 31.013L4.50453 9.81723H23.8486L22.1202 31.013ZM2.7495 5.97968L2.12883 7.82504H25.8714L25.2508 5.97968C25.2364 5.93713 25.1966 5.9086 25.1514 5.9086H2.84889C2.80364 5.9086 2.76362 5.93713 2.7495 5.97968Z"
      fill="#0F8344"
    />
    <path
      d="M19.9677 29.9987C19.9858 29.9997 20.0036 30 20.0216 30C20.561 30 21.0119 29.5865 21.04 29.0533L21.9986 11.0508C22.0278 10.5 21.5951 10.03 21.0323 10.0014C20.468 9.97205 19.9892 10.396 19.9597 10.9468L19.0014 28.9493C18.9722 29.5001 19.4047 29.9701 19.9677 29.9987Z"
      fill="#0F8344"
    />
    <path
      d="M6.99029 29.0557C7.01962 29.588 7.46321 30 7.99348 30C8.01181 30 8.03066 29.9995 8.04925 29.9984C8.60361 29.9685 9.02861 29.4978 8.99849 28.947L8.00945 10.9443C7.97933 10.3934 7.50537 9.97135 6.95075 10.0015C6.39639 10.0314 5.97139 10.5022 6.00151 11.053L6.99029 29.0557Z"
      fill="#0F8344"
    />
    <path
      d="M14.5 30C14.7762 30 15 29.5529 15 29.0013V10.9987C15 10.4471 14.7762 10 14.5 10C14.2238 10 14 10.4471 14 10.9987V29.0013C14 29.5529 14.2238 30 14.5 30Z"
      fill="#0F8344"
    />
  </svg>
);
