import { Button } from 'antd';

import './style.scss';

/**
 * @param {object} props
 */
function PdButton({ children, type = 'primary', className = '', ...props }) {
  return (
    <Button
      type={type}
      className={className ? `pd-button ${className}` : 'pd-button'}
      {...props}>
      {children}
    </Button>
  );
}

export default PdButton;
