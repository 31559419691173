import { useContext, useState } from 'react';
import { Input, Layout, message, Form } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { PdButton } from 'components';
import { AuthenticationContext } from 'contexts/authentication';

import './style.scss';

const { Content } = Layout;

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${name} is required!',
  types: {
    email: '${name} is not a valid email!',
    number: '${name} is not a valid number!',
  },
  number: {
    range: '${name} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */

export default function LoginPage() {
  const [loadingButton, seLoadingButton] = useState(false);
  const [form] = Form.useForm();

  const { login } = useContext(AuthenticationContext);

  async function handleLogin(payload) {
    try {
      seLoadingButton(true);
      await login({
        ...payload,
        email: payload.email.toLowerCase(),
      });
    } catch (err) {
      message.error(
        err?.response?.data?.message ??
          'Tidak dapat menghubungi server, cek koneksi'
      );
    } finally {
      seLoadingButton(false);
    }
  }

  return (
    <Content className="pd-login">
      <div className="logo-container pd-margin-bottom-lg">Dashboard Login</div>
      <Form
        onFinish={handleLogin}
        className="login-form"
        validateMessages={validateMessages}
        form={form}
        name="nest-messages">
        <Form.Item name="email" rules={[{ required: true }]}>
          <Input
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true }]}>
          <Input
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <PdButton
            htmlType="submit"
            loading={loadingButton}
            className="login-form-button">
            Log in
          </PdButton>
        </Form.Item>
      </Form>
    </Content>
  );
}
