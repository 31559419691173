import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import AuthenticationApi from 'api/authentication';

export const AuthenticationContext = createContext();

function AuthenticationContextProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(false);
  const [authenticatedUserProfile, setAuthenticatedUserProfile] = useState({
    name: '',
    email: '',
  });

  async function login(payload) {
    const { data } = await AuthenticationApi.login(payload);
    const { access_token, admin } = data?.data ?? {};

    if (access_token && admin) {
      localStorage.setItem('cms_token', access_token);
      localStorage.setItem('current_admin', JSON.stringify(admin));

      setAuthenticated(true);
      setAuthenticatedUserProfile(admin);
    }

    window.location.reload();
  }

  useEffect(() => {
    const currentAdmin = localStorage.getItem('current_admin');

    if (currentAdmin) {
      setAuthenticatedUserProfile(JSON.parse(currentAdmin));
    }
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        authenticated,
        authenticatedUserProfile,
        login,
      }}>
      {children}
    </AuthenticationContext.Provider>
  );
}

AuthenticationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthenticationContextProvider;
