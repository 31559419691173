export const baseColumns = [
  {
    title: 'Company Name',
    dataIndex: 'title',
    key: 'company_name',
    sorter: (a, b) => a.title.localeCompare(b.title),
  },
  {
    title: 'Request Time',
    dataIndex: 'requested_at',
    key: 'requested_at',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.requested_at.localeCompare(b.requested_at),
  },
  {
    title: 'Request For',
    dataIndex: 'request_for',
    key: 'request_for',
  },
  {
    title: 'Value',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Status',
    key: 'status',
  },
];
