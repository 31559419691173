import Icon from '@ant-design/icons';

import { DeleteSvg } from './delete-svg';

/**
 * @param {Object} props
 * @param {('small'|'medium')} props.size - The allowed type of the size
 */

function PdDeleteIcon({ size = 'medium', ...props }) {
  return (
    <Icon className={`pd-icon ${size}`} component={DeleteSvg} {...props} />
  );
}

export default PdDeleteIcon;
