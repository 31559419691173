import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { Col, Form, Input, Layout, Row } from 'antd';

import { PageSpinner, PdForm, PdButton, PdSwitch, Container } from 'components';
import AdminApi from 'api/admin';
import { AuthenticationContext } from 'contexts/authentication';
import { handleErr } from 'utils';

import './style.scss';

export default function AdminForm({ history, location }) {
  const [form] = Form.useForm();
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { page } = useParams();
  const { authenticatedUserProfile: currentAdmin = {} } = useContext(
    AuthenticationContext
  );

  async function fetchDetailAdmin() {
    setIsLoading(true);
    try {
      const { data } = await AdminApi.getDetailAdmin(location.state);
      const { first_name, last_name, email, super_admin, status } = data.data;
      form.setFieldsValue({
        first_name,
        last_name,
        email,
        super_admin,
        status,
      });
    } catch (error) {
      handleErr(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (page === 'edit') {
      fetchDetailAdmin();
    }
  }, []);

  function handleConfirmBlur(e) {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  }

  function validateToPassword(_, value) {
    if (value && value !== form.getFieldValue('password')) {
      return Promise.reject('Two passwords that you enter is inconsistent!');
    } else {
      return Promise.resolve();
    }
  }

  function handleBack() {
    history.push('/admins');
  }

  const handleOnFinish = async (values) => {
    values.status = values.status ? 'active' : 'inactive';
    try {
      if (page === 'create') {
        await AdminApi.createAdmin(values);
      } else {
        const id = location.state;
        await AdminApi.updateAdmin(id, values);
      }
      history.push('/admins');
    } catch (error) {
      handleErr(error);
    }
  };

  return (
    <Layout className="pd-cms-form-admin">
      <Container>
        {isLoading ? (
          <PageSpinner />
        ) : (
          <PdForm form={form} onFinish={handleOnFinish}>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                { required: true, message: 'Please input your First Name!' },
              ]}>
              <Input placeholder="Enter First Name" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: 'Please input your Last Name!',
                },
              ]}>
              <Input placeholder="Enter Last Name" />
              {/* )} */}
            </Form.Item>
            <Form.Item
              label="E-mail"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}>
              <Input placeholder="Enter Your Email" />
              {/* )} */}
            </Form.Item>
            {currentAdmin.super_admin ? (
              <Form.Item
                label="Super Admin"
                name="super_admin"
                valuePropName="checked">
                <PdSwitch />
              </Form.Item>
            ) : null}

            <Form.Item
              label={page === 'create' ? 'Password' : 'Change Password'}
              name="password"
              hasFeedback
              rules={[
                {
                  required: page === 'create',
                  message: 'Please input your Password!',
                },
              ]}>
              <Input.Password placeholder="Enter Password" />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="password_confirmation"
              hasFeedback
              rules={[
                {
                  required: page === 'create',
                  message: 'Please input your Confirm Password!',
                },
                {
                  validator: validateToPassword,
                },
              ]}>
              <Input.Password
                placeholder="Enter Confirm Password"
                onBlur={handleConfirmBlur}
              />
            </Form.Item>
            {page === 'edit' && currentAdmin.id !== location.state && (
              <Form.Item label="Status" name="status" valuePropName="checked">
                <PdSwitch />
              </Form.Item>
            )}
            <Form.Item className="btn-container">
              <Row align="middle" justify="center" type="flex">
                <Col lg={6}>
                  <PdButton block ghost type="primary" onClick={handleBack}>
                    Cancel
                  </PdButton>
                </Col>
                <Col lg={{ span: 6, offset: 1 }}>
                  <PdButton block htmlType="submit">
                    Submit
                  </PdButton>
                </Col>
              </Row>
            </Form.Item>
          </PdForm>
        )}
      </Container>
    </Layout>
  );
}
