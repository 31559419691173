import { Select } from 'antd';

import './style.scss';

const { Option } = Select;

export default function PdSelect({ className = '', options = [], ...props }) {
  return (
    <Select className={`pd-select ${className}`} {...props}>
      {options.map(({ value, text }) => {
        return (
          <Option key={value} value={value}>
            {text}
          </Option>
        );
      })}
    </Select>
  );
}
