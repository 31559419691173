import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { Layout } from 'antd';

import { MainProvider } from 'contexts';

import AuthenticationContext from './contexts/authentication';

import {
  Admin,
  AdminForm,
  Client,
  ClientForm,
  EmailRequest,
  Home,
  Invoice,
  InvoiceDetail,
  Login,
  TermAndPolicy,
  TermAndPolicyForm,
} from 'pages';

import AuthenticatedGuardRoute from 'components/guard/authenticated';
import NotAuthenticatedGuardRoute from 'components/guard/notAuthenticated';
import { Sidebar, Header } from 'components';

import 'config/antd.less';
import 'App.scss';

const { Content } = Layout;

// Weird that we mix UI and routes
function DashboardWrapper() {
  return (
    <Switch>
      <NotAuthenticatedGuardRoute path="/login" component={Login} />
      <Layout className="pd-cms">
        <Sidebar />
        <Layout>
          <Header />
          <Content>
            <AuthenticatedGuardRoute exact path="/" component={Home} />
            <AuthenticatedGuardRoute exact path="/admins" component={Admin} />
            <AuthenticatedGuardRoute
              exact
              path="/admins/:page"
              component={AdminForm}
            />
            <AuthenticatedGuardRoute exact path="/clients" component={Client} />
            <AuthenticatedGuardRoute
              exact
              path="/clients/:action"
              component={ClientForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/email-request"
              component={EmailRequest}
            />
            {/* temporary hide
            <AuthenticatedGuardRoute
              exact
              path="/invoices"
              component={Invoice}
            />
            <AuthenticatedGuardRoute
              exact
              path="/invoices/:id"
              component={InvoiceDetail}
            /> */}
            {/* <AuthenticatedGuardRoute
              exact
              path="/setting"
              component={Setting}
            /> */}
            <AuthenticatedGuardRoute
              exact
              path="/terms-and-policy"
              component={TermAndPolicy}
            />
            <AuthenticatedGuardRoute
              exact
              path="/terms-and-policy/:page"
              component={TermAndPolicyForm}
            />
          </Content>
        </Layout>
      </Layout>
    </Switch>
  );
}

// TODO: 404 page
// TODO: ErrorBoundary

function App() {
  return (
    <AuthenticationContext>
      <MainProvider>
        <Router>
          <DashboardWrapper />
        </Router>
      </MainProvider>
    </AuthenticationContext>
  );
}

export default App;
