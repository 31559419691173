import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { identity } from 'lodash';

import './style.scss';

export default function PdSearch({
  className = '',
  onChange = identity,
  ...props
}) {
  return (
    <Input
      className={`pd-search ${className}`}
      prefix={<SearchOutlined />}
      onChange={(ev) => onChange(ev.target.value)}
      {...props}
    />
  );
}
