import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './style.scss';

PageSpinner.propTypes = {
  className: PropTypes.string,
};

function PageSpinner(props) {
  const { className = '' } = props;

  return (
    <div className={`pd-section ${className}`}>
      <Spin size="large" indicator={<LoadingOutlined spin />} />
    </div>
  );
}

export default PageSpinner;
