import { Link } from 'react-router-dom';
import './style.scss';
import ImageSrc from 'assets/images/logo.png';

/**
 * @param {Object} props
 * @param {('small'|'medium')} props.size - The allowed type of the size
 */

export function Logo({ size = 'medium' }) {
  const company_logo = ImageSrc;
  return (
    <Link to="/">
      <img className={`logo-image-${size}`} src={company_logo} alt="logo" />
    </Link>
  );
}
