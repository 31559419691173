export const baseFields = [
  {
    label: 'Client ID',
    name: 'id',
    type: 'text',
    isDisabled: true,
    placeholder: 'Autofill',
  },
  {
    label: 'Company Name',
    name: 'title',
    type: 'text',
    placeholder: 'Enter Company Name',
    rules: [{ required: true, message: 'Please Input Company Name' }],
  },
  {
    label: 'Email',
    name: 'email_id',
    type: 'email',
    placeholder: 'Enter Email',
    rules: [{ required: true, message: 'Please Input Email' }],
  },
  {
    label: 'Subscription Type',
    name: 'subscription_type',
    type: 'custom',
    placeholder: 'Enter Subscription Type',
  },
  {
    label: 'Billing Address',
    name: 'address',
    type: 'text',
    placeholder: 'Enter Customer Billing Address',
  },
  {
    label: 'City',
    name: 'city',
    type: 'text',
    placeholder: 'Enter Customer City',
  },
  {
    label: 'State / Province',
    name: 'province',
    type: 'custom',
    placeholder: 'Choose state / province',
  },
  {
    label: 'Postal Code',
    name: 'postal_code',
    type: 'text',
    isHalf: true,
    placeholder: 'Enter Postal Code',
  },
  {
    label: 'Phone Number',
    name: 'phone_number',
    type: 'text',
    placeholder: 'Phone Number',
    isDisabled: true,
  },
  {
    label: 'Company Email',
    name: 'email',
    type: 'email',
    placeholder: 'Company Email',
    isDisabled: true,
  },
  {
    label: 'Proposal Email',
    name: 'proposal_email',
    type: 'email',
    placeholder: 'Proposal Email',
    isDisabled: true,
  },
  {
    label: 'Work Order Email',
    name: 'wo_email',
    type: 'email',
    placeholder: 'Work Order Email',
    isDisabled: true,
  },
  {
    label: 'Invoice Email',
    name: 'invoice_email',
    type: 'email',
    placeholder: 'Invoice Email',
    isDisabled: true,
  },
];
