import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Modal, message } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { startCase } from 'lodash';

import ClientApi from 'api/client';
import { subscription_types } from 'utils/constants/subscription-types';
import { handleErr } from 'utils';
import { AuthenticationContext } from 'contexts/authentication';

import {
  Container,
  PdButton,
  PdSearch,
  PdSelect,
  PdSwitch,
  PdTable,
  PageSpinner,
} from 'components';

const { confirm } = Modal;

import './style.scss';

export default function Client() {
  const subscriptionTypes = [
    {
      value: 'all',
      text: 'All',
    },
    ...subscription_types,
  ];
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const current_admin = JSON.parse(localStorage.getItem('current_admin'));
  const { authenticatedUserProfile: { super_admin } = {} } = useContext(
    AuthenticationContext
  );

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Company Name',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: 'Email',
      dataIndex: 'email_id',
      key: 'email_id',
    },
    {
      title: 'Subscription Type',
      dataIndex: 'subscription_type',
      key: 'subscription_type',
      render: renderSubscriptionType,
    },
    {
      title: 'Status',
      key: 'status',
      render: renderStatus,
    },
    {
      title: '',
      key: 'id',
      render: renderAction,
    },
  ];

  async function fetchClient({ q = '', subscription_type = '' }) {
    setIsLoading(true);
    try {
      const { data: response } = await ClientApi.getClientList({
        q,
        subscription_type,
      });
      const { data = [] } = response ?? {};

      setDataSource(data);
    } catch (error) {
      handleErr(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchClient({ q: query, subscription_type: subscriptionType });
  }, [query, subscriptionType, location.key]);

  function renderStatus(rowData = {}) {
    const { status, id } = rowData;
    return (
      <PdSwitch
        checked={status === 'active'}
        disabled={!super_admin}
        onChange={handleSwitchStatus.bind(this, [id, status])}
      />
    );
  }

  function renderSubscriptionType(subscription_type) {
    return <div className="text-role">{startCase(subscription_type)}</div>;
  }

  function renderAction(rowData = {}) {
    const { status, id } = rowData;
    if (!current_admin.super_admin) {
      return null;
    }

    if (status === 'active') {
      return (
        <Link to={{ pathname: '/clients/edit', state: id }}>
          <EditOutlined />
        </Link>
      );
    }

    return <DeleteOutlined onClick={() => handleDeleteClient(id)} />;
  }

  function handleFilter(value) {
    setSubscriptionType(value === 'all' ? '' : value);
  }

  async function handleSwitchStatus(id, status) {
    try {
      if (!status) {
        await ClientApi.deactivateClient(id);
      } else {
        await ClientApi.reactivateClient(id);
      }
      fetchClient({ q: query, subscription_type: subscriptionType });
    } catch (error) {
      handleErr(error);
    }
  }

  function handleDeleteClient(id) {
    confirm({
      title: 'Are you sure you want to delete this client?',
      content: "You can't undo this action",
      cancelButtonProps: { ghost: false },
      onOk: async () => {
        try {
          await ClientApi.deleteClient(id);
          message.success('Successfully delete a client');
          fetchClient({ q: query, subscription_type: subscriptionType });
        } catch (error) {
          handleErr(error);
        }
      },
    });
  }

  return (
    <div className="pd-client">
      <Container>
        <Row type="flex" gutter={10} justify="space-between">
          <Col span={20}>
            <Row gutter={10}>
              <Col span={12}>
                <PdSearch
                  placeholder="Search by customer name"
                  onChange={setQuery}
                />
              </Col>
              <Col span={6}>
                <PdSelect
                  options={subscriptionTypes}
                  defaultValue="all"
                  onChange={handleFilter}
                />
              </Col>
            </Row>
          </Col>
          {current_admin.super_admin ? (
            <Col>
              <Link to="/clients/create">
                <PdButton>Add New Client</PdButton>
              </Link>
            </Col>
          ) : null}
        </Row>
      </Container>
      <Container>
        {isLoading ? (
          <PageSpinner />
        ) : (
          <PdTable
            columns={columns}
            dataSource={dataSource}
            pagination={{
              onChange: setCurrentPage,
              pageSize: 5,
              defaultCurrent: currentPage,
            }}
          />
        )}
      </Container>
    </div>
  );
}
