import { REQUEST_FOR_VALUE } from './request-for-value';
import { subscription_types, UNLIMITED_PLAN_TERM } from './subscription-types';

const PATH_PAGE = {
  '/': 'Dashboard',
  '/clients': 'Clients',
  '/clients/create': 'Create Client',
  '/clients/edit': 'Edit Client',
  '/admins': 'Admins',
  '/admins/create': 'Create Admin',
  '/admins/edit': 'Edit Admin',
  '/email-request': 'Email Request',
  '/terms-and-policy': 'Terms and Policy',
  '/terms-and-policy/terms': 'Edit Terms',
  '/terms-and-policy/policy': 'Edit Policy',
};

export {
  REQUEST_FOR_VALUE,
  subscription_types,
  UNLIMITED_PLAN_TERM,
  PATH_PAGE,
};
