import { useEffect, useState, useContext } from 'react';
import { Col, Layout, Row, Modal, message } from 'antd';

import AdminApi from 'api/admin';

import {
  Container,
  PdTable,
  PdButton,
  PdSwitch,
  PageSpinner,
  PdSearch,
} from 'components';
import PdEditIcon from 'components/pd-edit-icon';
import PdDeleteIcon from 'components/pd-delete-icon';
import { AuthenticationContext } from 'contexts/authentication';
import getFullName from 'utils/getFullName';

import { handleErr } from 'utils';

import './style.scss';

const { confirm } = Modal;

export default function Admin({ history, location }) {
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const { authenticatedUserProfile: { id: currentAdminId, super_admin } = {} } =
    useContext(AuthenticationContext);

  const columns = [
    {
      title: 'Name',
      render: ColumnName,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      key: 'super_admin',
      render: ColumnRole,
    },
    {
      title: 'Status',
      key: 'status',
      render: ColumnStatus,
    },
    {
      title: '',
      key: 'id',
      render: ColumnAction,
      width: '30%',
    },
  ];

  function ColumnStatus(rowData = {}) {
    const { status, id: rowDataId } = rowData;
    return (
      <Row>
        <PdSwitch
          checked={status === 'active'}
          defaultChecked
          disabled={!super_admin}
          onChange={handleSwitchStatus.bind(this, [rowDataId, status])}
        />
      </Row>
    );
  }

  function ColumnName(rowData = {}) {
    const { first_name, last_name } = rowData;
    const fullName = getFullName(first_name, last_name);
    return <div>{fullName}</div>;
  }

  function ColumnRole(rowData = {}) {
    return (
      <div className="text-role">
        {rowData.super_admin ? <p>Super Admin</p> : <p>Admin</p>}
      </div>
    );
  }

  function ColumnAction(rowData = {}) {
    const { id: rowDataId, status } = rowData;

    if (super_admin) {
      return (
        <Row type="flex" justify="center" align="middle">
          {status === 'active' ? (
            <PdEditIcon onClick={() => handleEditAdmin(rowDataId)} />
          ) : (
            <PdDeleteIcon onClick={() => handleDeleteAdmin(rowDataId)} />
          )}
        </Row>
      );
    } else if (currentAdminId === rowDataId) {
      return (
        <Row type="flex" justify="center" align="middle">
          {rowData.status === 'active' ? (
            <PdEditIcon onClick={() => handleEditAdmin(rowDataId)} />
          ) : null}
        </Row>
      );
    }
  }

  function handleAddAdmin() {
    history.push({
      pathname: '/admins/create',
    });
  }

  async function handleSwitchStatus(id, status) {
    try {
      if (!status) {
        await AdminApi.deactivateAdmin(id);
      } else {
        await AdminApi.reactivateAdmin(id);
      }
      getListAdmin({ q: query });
    } catch (error) {
      handleErr(error);
    }
  }

  function handleDeleteAdmin(id) {
    confirm({
      title: 'Are you sure you want to delete this admin?',
      content: "You can't undo this action",
      cancelButtonProps: { ghost: false },
      onOk: async () => {
        // Todo hit api to delete admin
        try {
          await AdminApi.deleteAdmin(id);
          message.success('Successfully delete an admin');
          getListAdmin({ q: query });
        } catch (error) {
          handleErr(error);
        }
      },
    });
  }

  function handleEditAdmin(id) {
    history.push('admins/edit', id);
  }

  async function getListAdmin({ q = '' }) {
    setIsLoading(true);
    try {
      const { data: response } = await AdminApi.listAdmin({
        q,
      });
      const { data = [] } = response ?? {};
      setDataSource(data);
    } catch (error) {
      handleErr(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getListAdmin({ q: query });
  }, [query, location.key]);

  return (
    <Layout className="pd-cms-admin">
      {/* Section that display chart */}
      <Container>
        <Row align="middle" type="flex">
          <Col lg={10}>
            <PdSearch placeholder="Search Name" onChange={setQuery} />
          </Col>
          <Col lg={{ span: 3, offset: 11 }}>
            {super_admin && (
              <PdButton block onClick={handleAddAdmin}>
                Add New
              </PdButton>
            )}
          </Col>
        </Row>
      </Container>
      {isLoading ? (
        <PageSpinner />
      ) : (
        <Container>
          <Row align="middle">
            <Col span={24}>
              <PdTable
                dataSource={dataSource}
                columns={columns}
                pagination={{
                  onChange: setCurrentPage,
                  pageSize: 5,
                  defaultCurrent: currentPage,
                }}
              />
            </Col>
          </Row>
        </Container>
      )}
    </Layout>
  );
}
