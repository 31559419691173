import { useEffect, useState } from 'react';
import { Col, Layout, Tag, Row, Modal } from 'antd';
import Search from 'antd/lib/input/Search';
import queryString from 'query-string';

import {
  Container,
  PageSpinner,
  PdButton,
  PdSwitch,
  PdTable,
} from 'components';

import { roleMockData, FORM_ROLE_FORMATTER } from './mockData';

import PdEditIcon from 'components/pd-edit-icon';
import PdDeleteIcon from 'components/pd-delete-icon';

import './style.scss';

const { confirm } = Modal;

export default function Role({ history, location }) {
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const parsed = queryString.parse(location.search);

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: 'Page Access',
      dataIndex: 'permission',
      key: 'permission',
      render: ColumnAccess,
      width: '35%',
    },
    {
      title: 'Status',
      key: 'status',
      render: ColumnStatus,
    },
    {
      title: '',
      key: 'id',
      render: ColumnAction,
      width: '30%',
    },
  ];

  function ColumnStatus(rowData = {}) {
    console.log(rowData, 'asdasd');
    return (
      <Row>
        <PdSwitch
          checked={rowData.status === 'active' ? true : false}
          defaultChecked
          onChange={handleSwitchStatus.bind(this, [rowData.id, rowData.status])}
        />
      </Row>
    );
  }
  function ColumnAccess(access = []) {
    return (
      <>
        {access.map((acc, idx) => {
          return <Tag key={idx}>{FORM_ROLE_FORMATTER[acc]}</Tag>;
        })}
      </>
    );
  }

  function ColumnAction(rowData = {}) {
    return (
      <Row type="flex" justify="center" align="middle">
        {rowData.status === 'active' ? (
          <PdEditIcon onClick={handleEditRole.bind(this, rowData)} />
        ) : (
          <PdDeleteIcon
            onClick={handleDeleteRole.bind(this, [rowData, rowData.status])}
          />
        )}
      </Row>
    );
  }

  function handleAddRole() {
    history.push({
      pathname: '/roles/create',
    });
  }

  async function handleSwitchStatus(id, status) {
    // TODO handle patch of status 'active' and 'inactive'
  }

  function handleDeleteRole([rowData, status]) {
    confirm({
      title: 'Are you sure you want to delete this role?',
      content: "You can't undo this action",
      cancelButtonProps: { ghost: false },
      onOk: async () => {
        // Todo hit api to delete role
      },
    });
  }

  function handleEditRole(rowData) {
    handleEdit(rowData);
  }

  function getListRole() {
    // TODO: handling fetch data to api and loading/error state
    setDataSource(roleMockData);
  }

  function handleSearch(q) {
    history.push({
      pathname: '/roles',
      search: queryString.stringify(
        q && {
          q,
        }
      ),
    });
  }

  function handleEdit(detailData) {
    history.push('roles/edit', detailData);
  }

  useEffect(() => {
    getListRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  return (
    <Layout className="pd-cms-role">
      {/* Section that display chart */}
      <Container>
        <Row align="middle" type="flex">
          <Col lg={10}>
            <Search placeholder="Search Name" onSearch={handleSearch} />
          </Col>
          <Col lg={{ span: 3, offset: 11 }}>
            <PdButton block onClick={handleAddRole}>
              Add New
            </PdButton>
          </Col>
        </Row>
      </Container>
      {isLoading ? (
        <PageSpinner />
      ) : (
        <Container>
          {/* Section that display chart */}
          <Row align="middle">
            <Col span={24}>
              <PdTable
                dataSource={dataSource}
                columns={columns}
                pagination={{ pageSize: 5 }}
              />
            </Col>
          </Row>
        </Container>
      )}
    </Layout>
  );
}
