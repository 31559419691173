import { Row, Col } from 'antd';

import './style.scss';

/**
 * This component is used for adding a white background in the dashboard view
 */
function DashboardContainer({ children = {}, className = '', ...props }) {
  return (
    <Row className={`pd-container ${className}`} {...props}>
      <Col span={24}>{children}</Col>
    </Row>
  );
}

export default DashboardContainer;
