import axios from 'axios';

const createAPI = (baseURL = process.env.REACT_APP_API_URL, config = {}) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('cms_token')}`,
    'Cms-Key': process.env.REACT_APP_CMS_KEY,
  };

  const axiosInstance = axios.create({
    baseURL,
    withCredentials: false,
    headers,
    ...config,
  });
  // setup axios.intercept
  return axiosInstance;
};

const api = createAPI(`${process.env.REACT_APP_API_URL}/v1/company_cms`);

export default api;
