import { EditOutlined } from '@ant-design/icons';
import './style.scss';

/**
 * @param {Object} props
 * @param {('small'|'medium')} props.size - The allowed type of the size
 */

function PdEditIcon({ size = 'medium', className, ...props }) {
  return (
    <EditOutlined
      className={`pd-icon ${size} ${className ? className : ''}`}
      {...props}
    />
  );
}

export default PdEditIcon;
