import api from './index';
import { omitBy } from 'lodash';
import { isNil } from 'utils';

export default {
  getEmailRequestList({ status = '', q = '', request_for = '' } = {}) {
    const params = {
      status,
      q,
      request_for,
    };
    return api.get('/email_requests', {
      params: omitBy(params, isNil),
    });
  },
  changeStatus(id, payload) {
    return api.put(`/email_requests/${id}`, {
      email_request: { status: payload },
    });
  },
};
