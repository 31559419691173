import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';

import {
  Container,
  PdSearch,
  PdSelect,
  PdTable,
  PageSpinner,
} from 'components';
import EmailRequestApi from 'api/email-request';
import { handleErr } from 'utils';
import { dateFormatter } from 'utils/dateFormatter';
import { REQUEST_FOR_VALUE as RequestForValue } from 'utils/constants/request-for-value';

import { baseColumns } from './meta';
import { selectOptions, requestForSelectOptions } from './mock-data';

export default function EmailRequest() {
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [status, setStatus] = useState('');
  const [requestFor, setRequestFor] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const columns = baseColumns.map((col) => {
    const { key = '' } = col;
    if (key === 'status') {
      col['render'] = renderStatus;
    }

    return col;
  });

  async function fetchEmailRequest({ q = '', status = '', request_for = '' }) {
    setIsLoading(true);
    try {
      const { data: response } = await EmailRequestApi.getEmailRequestList({
        q,
        status,
        request_for,
      });
      const data = response?.data?.map((emailRequest) => {
        const {
          company = {},
          requested_at = '',
          request_for = '',
        } = emailRequest;
        return {
          ...emailRequest,
          title: company.title,
          requested_at: dateFormatter(requested_at),
          request_for: RequestForValue[request_for],
        };
      });
      setDataSource(data);
    } catch (error) {
      handleErr(error);
    } finally {
      setIsLoading(false);
    }
  }

  function handleFilterByStatus(value) {
    setStatus(value === 'all' ? '' : value);
  }

  function handleFilterByRequestFor(value) {
    setRequestFor(value === 'all' ? '' : value);
  }

  async function handleChangeStatus(payload, id) {
    setIsLoading(true);
    try {
      await EmailRequestApi.changeStatus(id, payload);
      fetchEmailRequest({ q: query, status, request_for: requestFor });
    } catch (error) {
      handleErr(error);
    }
  }

  useEffect(() => {
    fetchEmailRequest({ q: query, status, request_for: requestFor });
  }, [status, query, requestFor]);

  function renderStatus(rowData = {}) {
    const { status, id } = rowData;
    const options = selectOptions.filter(({ value }) => value !== 'all');
    return (
      <PdSelect
        options={options}
        defaultValue={status}
        onChange={(newStatus) => handleChangeStatus(newStatus, id)}
      />
    );
  }

  return (
    <div className="pd-email-request">
      <Container>
        <Row type="flex" gutter={10} justify="space-between">
          <Col span={20}>
            <Row gutter={10}>
              <Col span={12}>
                <PdSearch placeholder="Search name" onChange={setQuery} />
              </Col>
              <Col span={6}>
                <PdSelect
                  options={requestForSelectOptions}
                  onChange={handleFilterByRequestFor}
                  placeholder="Request For"
                />
              </Col>
              <Col span={6}>
                <PdSelect
                  options={selectOptions}
                  onChange={handleFilterByStatus}
                  placeholder="Status"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container>
        {isLoading ? (
          <PageSpinner />
        ) : (
          <PdTable
            columns={columns}
            dataSource={dataSource}
            pagination={{
              onChange: setCurrentPage,
              pageSize: 5,
              defaultCurrent: currentPage,
            }}
          />
        )}
      </Container>
    </div>
  );
}
