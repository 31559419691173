export const listOfLogo = [
  {
    alt: 'client-list',
    id: 1,
    imgSrc: 'img/logo/Group 41.png',
    link: '/clients',
  },
  {
    alt: 'payment',
    id: 2,
    imgSrc: 'img/logo/Group 43.png',
    link: '/payment',
  },
  {
    alt: 'email-request',
    id: 3,
    imgSrc: 'img/logo/Group 33.png',
    link: '/email-request',
  },
  {
    alt: 'admins-list',
    id: 4,
    imgSrc: 'img/logo/Group 44.png',
    link: '/admins',
  },
];
