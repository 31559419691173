import { Col, Form, Input, Row } from 'antd';
import PdButton from 'components/pd-button';
import { identity } from 'lodash';

const { Item } = Form;

function renderField({ field = {}, vertical = false }) {
  const {
    label = '',
    name = '',
    type = '',
    placeholder = '',
    isDisabled = false,
    render = [],
    isHalf,
    rules,
  } = field;
  let fieldItem = '';

  switch (type) {
    case 'custom':
      fieldItem = render;
      break;
    case 'password':
      fieldItem = <Input.Password placeholder={placeholder} />;
      break;
    default:
      fieldItem = (
        <Input placeholder={placeholder} disabled={isDisabled} type={type} />
      );
      break;
  }

  return (
    <div>
      <Item
        label={label}
        name={name}
        colon={false}
        labelAlign="left"
        labelCol={{ span: 4 }}
        rules={rules}
        wrapperCol={{ span: vertical ? 24 : isHalf ? 10 : 20 }}>
        {fieldItem}
      </Item>
    </div>
  );
}

export default function PdFormItem({
  className = '',
  fields = [],
  onSubmit = identity,
  onClickBack = identity,
  vertical = false,
  ...props
}) {
  return (
    <Form
      className={`pd-form ${className}`}
      layout={vertical ? 'vertical' : 'horizontal'}
      onFinish={onSubmit}
      {...props}>
      {fields.map((field) => renderField({ field, vertical }))}
      <Row
        type="flex"
        justify="center"
        gutter={16}
        className="pd-margin-top-lg">
        <Col span={5}>
          <PdButton block ghost onClick={onClickBack}>
            Back
          </PdButton>
        </Col>
        <Col span={5}>
          <PdButton htmlType="submit" block>
            Submit
          </PdButton>
        </Col>
      </Row>
    </Form>
  );
}
