import { message } from 'antd';

export const handleErr = (error) => {
  return message.error(
    error.response?.data?.message ??
      'Cannot connect to server, please check your connection'
  );
};

export function isNil(key) {
  return key === undefined || key === null || key === '';
}
