import AuthenticationContextProvider from 'contexts/authentication';

/**
 * Wrapper function for all Provider
 * @param {Object} props
 */
export function MainProvider({ children }) {
  return (
    <AuthenticationContextProvider>{children}</AuthenticationContextProvider>
  );
}
