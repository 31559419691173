export const subscription_types = [
  {
    value: 'basic',
    text: 'Basic',
  },
  {
    value: 'unlimited',
    text: 'Unlimited',
  },
];

export const UNLIMITED_PLAN_TERM = [
  'unlimited plan',
  'unlimited_plan',
  'unlimited',
];
