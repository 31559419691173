import api from './index';
import { omitBy } from 'lodash';
import { isNil } from 'utils';

const current_admin = JSON.parse(localStorage.getItem('current_admin'));

export default {
  createAdmin(payload) {
    return api.post(`/admins`, { admin: payload });
  },
  listAdmin({ q = '' } = {}) {
    const params = {
      q,
    };
    return api.get('/admins', {
      params: omitBy(params, isNil),
    });
  },
  updateAdmin(id, payload) {
    const url = current_admin.super_admin ? `/admins/${id}` : `/admins/me`;
    return api.put(url, { admin: payload });
  },
  changeAdminPassword(payload) {
    return api.post(`/admins/change_password`, payload);
  },
  deleteAdmin(id) {
    return api.delete(`/admins/${id}/delete`);
  },
  deactivateAdmin(id) {
    return api.delete(`/admins/${id}`);
  },
  reactivateAdmin(id) {
    return api.put(`/admins/${id}/reactivate`);
  },
  getDetailAdmin(id) {
    const url = id === current_admin.id ? `/admins/me` : `/admins/${id}`;
    return api.get(url);
  },
};
