export const selectOptions = [
  {
    value: 'all',
    text: 'All',
  },
  {
    value: 'new_request',
    text: 'New Request',
  },
  {
    value: 'uploaded',
    text: 'Uploaded to SES',
  },
  {
    value: 'verified',
    text: 'Verified',
  },
];

export const requestForSelectOptions = [
  {
    value: 'all',
    text: 'All',
  },
  {
    value: 'company_email',
    text: 'Company Email',
  },
  {
    value: 'wo_email',
    text: 'Work Order Email',
  },
  {
    value: 'proposal_email',
    text: 'Proposal Email',
  },
  {
    value: 'invoice_email',
    text: 'Invoice Email',
  },
];

export const dataSource = [
  {
    id: 10,
    company_name: 'ABC',
    request_time: '06/06/21, 01:00',
    request_for: 'Company Mail',
    value: 'abc@mail.com',
    status: 'new_request',
  },
];
