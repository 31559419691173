import api from './index';
import { omitBy } from 'lodash';
import { isNil } from 'utils';

export default {
  getClientList({ q = '', subscription_type = '' } = {}) {
    const params = {
      q,
      subscription_type,
    };
    return api.get('/companies', {
      params: omitBy(params, isNil),
    });
  },
  deactivateClient(id) {
    return api.delete(`/companies/${id}`);
  },
  reactivateClient(id) {
    return api.put(`/companies/${id}/reactivate`);
  },
  deleteClient(id) {
    return api.delete(`/companies/${id}/delete`);
  },
  createCompany(payload) {
    return api.post('/companies', { company: payload });
  },
  getCompanyDetails(id) {
    return api.get(`/companies/${id}`);
  },
  updateCompany(id, payload) {
    return api.put(`/companies/${id}`, { company: payload });
  },
};
