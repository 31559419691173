import { Row, Col, Layout } from 'antd';
import { Link } from 'react-router-dom';
import DashboardContainer from 'components/container';

import { listOfLogo } from './mockData';
import './style.scss';

export default function Home() {
  return (
    <Layout className="pd-cms-home">
      <DashboardContainer>
        <Row
          gutter={[10, 48]}
          type="flex"
          className="section"
          align="middle"
          justify="center">
          {listOfLogo.map(({ id, imgSrc, link, alt }) => (
            <Col lg={{ span: 8 }} key={id} className="icon-container">
              <Row align="middle" justify="center" type="center">
                <Link to={link}>
                  <img src={imgSrc} alt={alt} height={120} width={90} />
                </Link>
              </Row>
            </Col>
          ))}
        </Row>
      </DashboardContainer>
    </Layout>
  );
}
