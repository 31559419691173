import { useContext } from 'react';
import { Row, Col, Typography } from 'antd';
import { useLocation } from 'react-router-dom';

import { Logo } from 'components/logo';
import { AuthenticationContext } from 'contexts/authentication';
import getFullName from 'utils/getFullName';

import './style.scss';
import { PATH_PAGE } from 'utils/constants';

const { Title } = Typography;

export default function Header() {
  const { authenticatedUserProfile: { first_name, last_name } = {} } =
    useContext(AuthenticationContext);
  const name = getFullName(first_name, last_name);

  const location = useLocation();

  return (
    <>
      <header className="pd-header">
        <Row type="flex" align="middle">
          <Col xs={0} lg={4}>
            <Logo size="small" />
          </Col>
          <Col xs={12} lg={16}>
            <Title
              level={3}
              className="pd-align-center"
              style={{ marginBottom: 0 }}>
              {PATH_PAGE[location?.pathname] || '-'}
            </Title>
          </Col>
          <Col xs={12} lg={4}>
            <div className="pd-header-right">{name}</div>
          </Col>
        </Row>
      </header>
    </>
  );
}
