import { Col, Row, Form, Input } from 'antd';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import StateApi from 'api/state';
import ClientApi from 'api/client';
import { Container, PdSelect, PageSpinner, PdFormItem } from 'components';
import { handleErr } from 'utils';

import { baseFields } from './meta';
import {
  subscription_types,
  UNLIMITED_PLAN_TERM,
} from 'utils/constants/subscription-types';
import './style.scss';

export default function ClientForm({ history, location }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [states, setStates] = useState([]);
  const { action } = useParams();

  const fields = baseFields.map((field) => {
    const { name = '' } = field;
    if (name === 'province') {
      field['render'] = renderProvinceField();
    } else if (name === 'subscription_type') {
      field['render'] = renderSubscriptionTypeField();
    }

    return field;
  });

  async function handleSubmit(values) {
    setIsLoading(true);
    try {
      if (action === 'create') {
        await ClientApi.createCompany(values);
      } else {
        const id = location.state;
        await ClientApi.updateCompany(id, values);
      }
      history.push('/clients');
    } catch (error) {
      handleErr(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchData() {
    setIsLoading(true);
    try {
      const { data = {} } = await ClientApi.getCompanyDetails(location.state);
      const { data: clientData = {} } = data;
      const { state = {}, subscription_type = '' } = clientData;

      form.setFieldsValue({
        ...clientData,
        subscription_type: UNLIMITED_PLAN_TERM.includes(subscription_type)
          ? 'unlimited'
          : subscription_type,
        states_code: state.code,
        country: state.country,
      });
    } catch (error) {
      handleErr(error);
    }
  }

  async function getStates() {
    setIsLoading(true);
    try {
      const { data } = await StateApi.getStates();
      const stateData = data?.data?.map(
        ({ code: value = '', name: text = '', country = '' }) => {
          return { value, text, country };
        }
      );

      setStates(stateData);
    } catch (error) {
      handleErr(error);
    } finally {
      setIsLoading(false);
    }
  }

  function changeCountry(value) {
    const { country } = states.find((state) => state.value === value);
    form.setFieldsValue({ country });
  }

  useEffect(() => {
    getStates();
    if (action === 'edit') {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderProvinceField() {
    return (
      <Row type="flex" justify="space-between">
        <Col span={8}>
          <Form.Item name="states_code">
            <PdSelect
              options={states}
              placeholder="Choose State / Province"
              onChange={changeCountry}
            />
          </Form.Item>
        </Col>
        <Col span={14}>
          <Form.Item
            name="country"
            label="Country"
            labelAlign="left"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            style={{ marginBottom: 0 }}>
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
    );
  }

  function renderSubscriptionTypeField() {
    return (
      <Form.Item name="subscription_type">
        <PdSelect
          options={subscription_types}
          placeholder="Choose Subscription Type"
        />
      </Form.Item>
    );
  }

  return (
    <Container>
      {isLoading ? (
        <PageSpinner></PageSpinner>
      ) : (
        <PdFormItem
          form={form}
          fields={fields}
          onSubmit={handleSubmit}
          onClickBack={() => history.goBack()}
        />
      )}
    </Container>
  );
}
